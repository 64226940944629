import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  justify-content: left;
`;

export const QuestionHeading = styled.h2`
  ${fonts.headingSmall}

  margin: 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(10)} 0 ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(12)};
  `}
`;
