import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import FormFooter from 'components/FormFooter';

export const FormFooterWithTopMargin = styled(FormFooter)`
  margin-top: ${spacing(8)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;
