import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CoverStartDateQuestion from 'components/CoverStartDate/CoverStartDateQuestion';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { FormFooterWithTopMargin } from './styles';

type ConfirmCoverStartDateFormData = {
  csPetAboutYouAndYourPet: {
    next_button_text: string;
  };
};

const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      next_button_text
    }
  }
`;

type ConfirmCoverStartDateFormProps = {
  moveNext: () => void;
  questionText?: string;
};

const ConfirmCoverStartDateForm: React.FC<ConfirmCoverStartDateFormProps> = ({
  moveNext,
  questionText,
}) => {
  const {
    csPetAboutYouAndYourPet: { next_button_text },
  } = useStaticQuery<ConfirmCoverStartDateFormData>(query);

  // If  any of the Pets are too young at the current date, we restrict the date picker
  // to ensure they are valid at the cover start date and display a special message. The
  // pets should be at least 8 weeks old restriction if the quote comes from the JL site,
  // but we don't know for sure for quotes coming from the aggs sites.
  const [minDate] = useFirstEligibleStartDateFromToday();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        moveNext();
      }}>
      {questionText && <RichText html={questionText} />}
      <CoverStartDateQuestion minDate={minDate} />
      <FormFooterWithTopMargin
        contentColumns={{ desktop: 6 }}
        moveNextButton={{
          text: next_button_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.ConfirmCoverStartDate, 'Move next'),
        }}
        pageTitle={PageTitle.ConfirmCoverStartDate}
      />
    </form>
  );
};

export default ConfirmCoverStartDateForm;
